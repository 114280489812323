import * as React from "react";
import {
  Datagrid,
  TextField,
  List,
  useNotify,
  TopToolbar,
  Button,
  TextInput,
  FunctionField,
} from "react-admin";
import { PostPagination } from "../../Components";
import { RECORDS_PER_PAGE } from "../../Constants";
import instance from "../../Core/service";
import { BASE_URL } from "../../Constants";
import CircularProgress from "@material-ui/core/CircularProgress";

function transformDate(date) {
  try {
    if (date && date.length > 1) {
      const dateObject = new Date(date);
      let day = dateObject.getDate();
      let month = dateObject.getMonth() + 1;
      const year = dateObject.getFullYear();
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return `${month}/${day}/${year}`;
    }
    return date;
  } catch (e) {
    return date;
  }
}

const ListActions = (props) => {
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);

  const handleReportGenerate = () => {
    return async () => {
      setLoading(true);
      const searchParams = new URLSearchParams(props.location.search);
      const sort = searchParams.get("sort") ?? "firstName";
      const order = searchParams.get("order") ?? "ASC";
      const filters = searchParams.get("filter");
      const searchTerm = JSON.parse(filters)?.q;
      instance
        .get(
          `${BASE_URL}/homebuyers/report/generate?_sort=${sort}&_order=${order}${
            searchTerm ? `&q=${searchTerm}` : ""
          }`
        )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            try {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const a = document.createElement("a");
              a.href = url;
              a.download = "data.csv";
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            } catch (error) {
              console.log(error);
            }
          } else {
            notify("Error in generating report", "error");
          }
        })
        .catch((e) => {
          setLoading(false);
          notify("Internal Server Error", "error");
        });
    };
  };

  return (
    <TopToolbar>
      <div style={{ display: "flex", alignItems: "center" }}>
        {loading && <CircularProgress color="inherit" thickness={3} size={15} />}
        <Button onClick={handleReportGenerate()} label="Generate Report" disabled={loading} />
      </div>
    </TopToolbar>
  );
};

export const BuyersActivityList = (props) => {
  return (
    <List
      {...props}
      actions={<ListActions {...props} />}
      bulkActionButtons={false}
      perPage={RECORDS_PER_PAGE}
      sort={{ field: "firstName", order: "ASC" }}
      pagination={<PostPagination />}
      filters={[
        <TextInput
          label="Search"
          source="q"
          placeholder="Email, FirstName, LastName, Community, Lot, LotAddress"
          alwaysOn
        />,
      ]}
    >
      <Datagrid>
        <TextField emptyText="-" source="community" sortable={true} label="Community" />
        <TextField emptyText="-" source="lastName" sortable={true} label="Last Name" />
        <TextField emptyText="-" source="firstName" sortable={true} label="First Name" />
        <TextField emptyText="-" source="lot" sortable={true} label="Lot" />
        <TextField emptyText="-" source="lotAddress" sortable={true} label="Lot Address" />
        <TextField emptyText="-" source="email" sortable={true} label="Email" />
        <TextField emptyText="-" source="buyerType" sortable={true} label="Buyer Type" />
        <TextField emptyText="-" source="contact" sortable={true} label="Contact(Phone)" />
        <FunctionField
          label="Last Activity"
          source="lastActivity"
          render={(record) => transformDate(record.lastActivity)}
          sortable={true}
          emptyText="-"
        />
        <TextField emptyText="-" source="totalVisits" sortable={true} label="Total Visits" />
        <TextField emptyText="-" source="unreadMessages" sortable={true} label="Unread Meassages" />
        <TextField emptyText="-" source="photos" sortable={true} label="Photos" />
        <TextField
          emptyText="-"
          source="designCentreImages"
          sortable={true}
          label="Design Centre Images"
        />
        <TextField
          emptyText="-"
          source="isElevationImage"
          sortable={true}
          label="Profile image"
        />
        <FunctionField
          label="Sale Date"
          source="saleDate"
          render={(record) => transformDate(record.saleDate)}
          sortable={true}
          emptyText="-"
        />
        <FunctionField
          label="Close Date"
          source="closedDate"
          render={(record) => transformDate(record.closedDate)}
          sortable={true}
          emptyText="-"
        />
        <FunctionField
          label="60 Day Submitted"
          source="sixtyDays"
          render={(record) => transformDate(record.sixtyDays)}
          sortable={true}
          emptyText="-"
        />
        <FunctionField
          label="11 Month Submitted"
          source="elevenMonths"
          render={(record) => transformDate(record.elevenMonths)}
          sortable={true}
          emptyText="-"
        />
      </Datagrid>
    </List>
  );
};
